import { DiscussionService } from "./DiscussionService";
import { DocumentsService } from "./DocumentsService";
import { FooterService } from "./FooterService";
import { IntroService } from "./IntroService";
import { PanelLinksService } from "./PanelLinksService";
import { StudentService } from "./StudentService";
import { UniversityService } from "./UniversityService";

const documentsService = new DocumentsService();
const footerService = new FooterService();
const introService = new IntroService();
const panelLinksService = new PanelLinksService();
const studentService = new StudentService();
const universityService = new UniversityService();
const discussionService = new DiscussionService();

export {
  documentsService,
  footerService,
  panelLinksService,
  studentService,
  universityService,
  discussionService,
  introService,
};
