
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import AppBar from "@/components/Common/AppBar/AppBar.vue";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";
import { User } from "@/types/User";

const UserStore = namespace(Module.User);

@Component({
  components: {
    AppBar,
  },
})
export default class App extends Vue {
  @UserStore.State
  readonly user!: User | null;

  // Make the Role enum accessible in template;
  Role = Role;

  @UserStore.Action
  loadUserFromCookies!: () => void;

  mounted() {
    this.loadUserFromCookies();
  }
}
