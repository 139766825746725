
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { footerService } from "@/services";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";
import {
  FooterItem,
  FooterItemLinkType,
} from "@/types/generated/DifcamWebTypes";

const UserModule = namespace(Module.User);

@Component({})
export default class AppFooter extends Vue {
  footerItems: FooterItem[] = [];

  isDialogOpen = false;
  isDialogContentLoading = false;
  isDialogContentInError = false;
  dialogContent = "";

  FooterItemLinkType = FooterItemLinkType;

  @UserModule.State
  readonly currentSpace!: Role;

  mounted() {
    this.loadFooterItems();
  }

  loadFooterItems() {
    this.isDialogContentLoading = true;
    this.isDialogContentInError = false;
    footerService
      .loadFooterItems()
      .then((footerItems) => {
        this.footerItems = footerItems;
      })
      .catch(() => {
        this.isDialogContentInError = true;
      })
      .finally(() => {
        this.isDialogContentLoading = false;
      });
  }

  loadFooterContent(contentId: string) {
    this.isDialogOpen = true;
    this.dialogContent = "";
    footerService
      .loadFooterContent(contentId)
      .then((result) => {
        this.dialogContent = result.htmlContent;
      })
      .catch(() => {
        // Nothing here
      });
  }

  get footerItemsToDisplay() {
    return this.footerItems.filter((item) =>
      item.roles.includes(this.currentSpace)
    );
  }
}
