import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { panelLinksService } from "@/services";
import { Module as ModuleNamespace } from "@/store/Module";
import { Role } from "@/types/Role";
import {
  PanelLinksItem,
  PanelLinksItemType,
  University,
} from "@/types/generated/DifcamWebTypes";

@Module({ namespaced: true })
export class PanelModule extends VuexModule {
  get universityLinkUrl(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const university: University = (this.context.rootState as any)[
      ModuleNamespace.User
    ].university;

    if (university) {
      return university.website;
    }

    return "";
  }

  get userRole(): Role {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const role: Role = (this.context.rootState as any)[ModuleNamespace.User]
      .currentSpace;

    return role;
  }

  mainButtonsLink: PanelLinksItem[] = [];
  imageButtonsLink: PanelLinksItem[] = [];
  iconButtonsLink: PanelLinksItem[] = [];

  @Mutation
  setMainButtonsLink(mainButtonsLink: PanelLinksItem[]) {
    this.mainButtonsLink = mainButtonsLink;
  }

  @Mutation
  setImageButtonsLink(imageButtonsLink: PanelLinksItem[]) {
    this.imageButtonsLink = imageButtonsLink;
  }

  @Mutation
  setIconButtonsLink(iconButtonsLink: PanelLinksItem[]) {
    this.iconButtonsLink = iconButtonsLink;
  }

  @Action
  loadPanelItems() {
    panelLinksService
      .getPanelLinksItems()
      .then((panelLinksItems) => {
        this.context.commit(
          "setMainButtonsLink",
          panelLinksItems.filter(
            (link) =>
              link.type === PanelLinksItemType.MAINBUTTON &&
              link.spaces.includes(this.userRole)
          )
        );
        this.context.commit(
          "setImageButtonsLink",
          panelLinksItems.filter(
            (link) =>
              link.type === PanelLinksItemType.IMAGEBUTTON &&
              link.spaces.includes(this.userRole)
          )
        );
        this.context.commit(
          "setIconButtonsLink",
          panelLinksItems.filter(
            (link) =>
              link.type === PanelLinksItemType.ICONBUTTON &&
              link.spaces.includes(this.userRole)
          )
        );
      })
      .catch(() => {
        // Nothing here.
      });
  }
}
