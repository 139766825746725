
import { Component, Vue } from "vue-property-decorator";

import CourseHeader from "./CourseHeader.vue";

@Component({
  components: {
    CourseHeader,
  },
})
export default class CourseErrorHeader extends Vue {}
