
import { Component, Prop, Vue } from "vue-property-decorator";

import { AnswerItem } from "@/types/generated/DifcamWebTypes";

import ForumAnswerItem from "./ForumAnswerItem.vue";

@Component({
  components: {
    ForumAnswerItem,
  },
})
export default class ForumAnswerSection extends Vue {
  @Prop()
  readonly answers!: AnswerItem[];

  get answersSortedByDate() {
    return this.answers.sort(
      (a, b) =>
        new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
    );
  }
}
