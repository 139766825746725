
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { discussionService } from "@/services";
import { Module } from "@/store/Module";
import { User } from "@/types/User";
import { CreateAnswerParameter } from "@/types/generated/DifcamWebTypes";

import ForumAnswerItem from "./ForumAnswerItem.vue";

const UserModule = namespace(Module.User);
const DiscussionModule = namespace(Module.Discussion);

@Component({
  components: {
    ForumAnswerItem,
  },
})
export default class ForumNewAnswer extends Vue {
  focusOnField = false;
  newAnswerContent = "";
  isInError = false;

  @UserModule.State
  readonly user!: User | null;

  @Prop()
  readonly parentId!: number;

  @Prop()
  readonly focusInput!: boolean;

  @DiscussionModule.Action
  loadDiscussions!: () => void;

  refreshDiscussions() {
    this.loadDiscussions();
  }

  handleSend() {
    this.createAnswer({
      content: this.newAnswerContent,
      authorName: this.user ? this.user.name : "",
      authorEmail: this.user ? this.user.email : "",
      postId: this.parentId,
    });
  }

  createAnswer(createAnswerParameter: CreateAnswerParameter) {
    discussionService
      .createAnswer(createAnswerParameter)
      .then(() => {
        this.refreshDiscussions();
        this.resetFields();
      })
      .catch(() => {
        this.isInError = true;
      });
  }

  resetFields() {
    this.newAnswerContent = "";
  }

  get displayAnswerButton() {
    return this.focusOnField || this.newAnswerContent.length > 0;
  }
}
