
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Module } from "@/store/Module";
import { PanelLinksItem } from "@/types/generated/DifcamWebTypes";

const PanelModule = namespace(Module.Panel);
const UserModule = namespace(Module.User);

@Component({})
export default class PanelMainButtons extends Vue {
  @PanelModule.State
  readonly mainButtonsLink!: PanelLinksItem[];

  @PanelModule.Getter
  readonly universityLinkUrl!: string;

  @UserModule.State
  readonly foundInSyfadis!: boolean;

  get linksToDisplay() {
    return this.mainButtonsLink.filter(
      (link) => !link.displayOnlyToSyfadisUsers || this.foundInSyfadis
    );
  }
}
