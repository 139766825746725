
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import SectionTitle from "@/components/Common/SectionTitle/SectionTitle.vue";
import { Module } from "@/store/Module";
import { DiscussionItem } from "@/types/generated/DifcamWebTypes";

import ForumCreationDialog from "./ForumCreationDialog.vue";
import ForumItem from "./ForumItem.vue";

const DiscussionModule = namespace(Module.Discussion);

@Component({
  components: {
    SectionTitle,
    ForumItem,
    ForumCreationDialog,
  },
})
export default class ForumPanel extends Vue {
  @DiscussionModule.State
  readonly isLoading!: boolean;

  @DiscussionModule.State
  readonly discussions!: DiscussionItem[];

  @DiscussionModule.State
  readonly isCreationDialogOpen!: boolean;

  @DiscussionModule.Action
  openCreationDialog!: () => void;

  @DiscussionModule.Action
  loadDiscussions!: () => void;

  get discussionsSortedByDate() {
    return this.discussions.sort(
      (a, b) =>
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    );
  }

  mounted() {
    this.loadDiscussions();
  }
}
