
import { Component, Vue } from "vue-property-decorator";

import DocumentDisplayer from "@/components/Common/DocumentsDisplayer/DocumentDisplayer.vue";

import CourseHeader from "../Common/Course/CourseHeader.vue";

@Component({
  components: {
    CourseHeader,
    DocumentDisplayer,
  },
})
export default class TrainingPartnerSpace extends Vue {}
