
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { discussionService } from "@/services";
import { Module } from "@/store/Module";
import { User } from "@/types/User";
import { CreateDiscussionParameter } from "@/types/generated/DifcamWebTypes";

const UserModule = namespace(Module.User);
const DiscussionModule = namespace(Module.Discussion);

@Component({})
export default class ForumCreationDialog extends Vue {
  isLoading = false;
  isInError = false;
  subject = "";
  body = "";
  isLocked = false;

  @UserModule.State
  readonly user!: User | null;

  @DiscussionModule.Action
  closeCreationDialog!: () => void;

  @DiscussionModule.Action
  loadDiscussions!: () => void;

  refreshDiscussions() {
    this.loadDiscussions();
  }

  handleSend() {
    this.createDiscussion({
      body: this.body,
      author: this.user ? this.user.name : "",
      subject: this.subject,
      isLocked: this.isLocked,
    });
  }

  createDiscussion(createDiscussionParameter: CreateDiscussionParameter) {
    discussionService
      .createDiscussion(createDiscussionParameter)
      .then(() => {
        this.refreshDiscussions();
        this.resetFields();
        this.closeCreationDialog();
      })
      .catch(() => {
        this.isInError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  resetFields() {
    this.subject = "";
    this.body = "";
    this.isLocked = false;
  }
}
