
import { Component, Vue } from "vue-property-decorator";

import Course from "@/components/Common/Course/Course.vue";
import DocumentDisplayer from "@/components/Common/DocumentsDisplayer/DocumentDisplayer.vue";

@Component({
  components: {
    Course,
    DocumentDisplayer,
  },
})
export default class StudentSpace extends Vue {}
