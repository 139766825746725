
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { introService } from "@/services";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";
import { IntroItem } from "@/types/generated/DifcamWebTypes";

const UserModule = namespace(Module.User);

@Component({})
export default class AppIntro extends Vue {
  introItems: IntroItem[] = [];

  @UserModule.State
  readonly currentSpace!: Role;

  mounted() {
    this.loadIntroItems();
  }

  loadIntroItems() {
    introService
      .loadIntroItems()
      .then((introItems) => {
        this.introItems = introItems;
      })
      .catch(() => {
        // Nothing here.
      });
  }

  get introToDisplay() {
    return this.introItems.filter((intro) =>
      intro.roles.includes(this.currentSpace)
    );
  }
}
