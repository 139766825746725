import { Role } from "@/types/Role";

import { ensureExhaustive } from "./ensureExhaustive";

const getRoleLabel = (role: Role) => {
  switch (role) {
    case Role.Student:
      return "Alternant";
    case Role.StudiesDirector:
      return "Responsable pédagogique";
    case Role.Administrator:
      return "Administrateur";
    case Role.Trainer:
      return "Intervenant pédagogique";
    case Role.TrainingPartner:
      return "Maître d'apprentissage";
    case Role.PortalUser:
      return "Utilisateur du portail";
    default:
      return ensureExhaustive(role);
  }
};

export { getRoleLabel };
