
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class CourseHeader extends Vue {
  @Prop()
  readonly subtitle!: string;

  @Prop({ default: "Parcours diplômant" }) title!: string;
  @Prop({ default: require(`@/assets/diplome_bleu.png`) }) imgSrc!: string;
  @Prop({ default: "Logo d'un diplôme bleu" }) alt!: string;
}
