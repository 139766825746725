import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { documentsService } from "@/services";
import { DocumentsItem } from "@/types/generated/DifcamWebTypes";

@Module({ namespaced: true })
export class DocumentsModule extends VuexModule {
  documents: DocumentsItem[] = [];

  @Mutation
  setDocuments(documents: DocumentsItem[]) {
    this.documents = documents;
  }

  @Action
  loadDocuments() {
    documentsService
      .getDocumentsItems()
      .then((documentsItems) => {
        this.context.commit("setDocuments", documentsItems);
      })
      .catch(() => {
        // Nothing here.
      });
  }
}
