import Vue from "vue";
import Vuex from "vuex";

import { DiscussionsModule } from "./DiscussionModule";
import { DocumentsModule } from "./DocumentsModule";
import { Module } from "./Module";
import { PanelModule } from "./PanelModule";
import { UserModule } from "./UserModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    [Module.User]: UserModule,
    [Module.Panel]: PanelModule,
    [Module.Documents]: DocumentsModule,
    [Module.Discussion]: DiscussionsModule,
  },
});
