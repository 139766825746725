
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { discussionService } from "@/services";
import { Module } from "@/store/Module";
import { AnswerItem } from "@/types/generated/DifcamWebTypes";

const DiscussionModule = namespace(Module.Discussion);

@Component({})
export default class ForumAnswerDeletionDialog extends Vue {
  isLoading = false;
  isInError = false;

  @Prop()
  answer!: AnswerItem;

  @Prop()
  isDeletionDialogOpen!: boolean;

  @Prop()
  closeDeletionDialog!: () => void;

  @DiscussionModule.Action
  loadDiscussions!: () => void;

  refreshDiscussions() {
    this.loadDiscussions();
  }

  handleDelete() {
    this.isLoading = true;
    discussionService
      .deleteAnswer(this.answer.id)
      .then(() => {
        this.refreshDiscussions();
        this.closeDeletionDialog();
      })
      .catch(() => {
        this.isInError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
