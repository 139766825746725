import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import store from "@/store";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";

import { NamedRoutes } from "./NamedRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: NamedRoutes.Login,
    props: true,
    meta: {
      allowAnonymous: true,
      title: "Connexion",
    },
    component: Login,
  },
  {
    path: "/",
    name: NamedRoutes.Home,
    component: Home,
    meta: {
      title: "Accueil",
    },
    beforeEnter(_to, _from, next) {
      const user = store.state[Module.User].user;

      if (user == null) {
        next({
          name: NamedRoutes.Login,
          params: { requestUrl: document.location.href },
        });
      } else if (!user.roles.includes(Role.PortalUser)) {
        window.location.assign(
          `/api/v0/account/logout?requestUrl=${user.redirectUrl}`
        );
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta?.title) {
    window.document.title = `${to.meta.title} - DIFCAM`;
  }

  next();
});

export default router;
