
import { Component, Prop, Vue } from "vue-property-decorator";

import CircleWaveLoader from "@/components/Common/CircleWaveLoader.vue";

@Component({
  components: {
    CircleWaveLoader,
  },
})
export default class Login extends Vue {
  @Prop()
  readonly requestUrl?: string;

  mounted() {
    window.location.assign(
      `/api/v0/account/signin?requestUrl=${this.requestUrl ?? "%2F"}`
    );
  }
}
