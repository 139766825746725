
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import PageContainer from "@/components/Common/PageContainer/PageContainer.vue";
import { Module } from "@/store/Module";
import { University } from "@/types/generated/DifcamWebTypes";

const UserModule = namespace(Module.User);

@Component({
  components: {
    PageContainer,
  },
})
export default class RoleRibbon extends Vue {
  @Prop()
  readonly label!: string;

  @UserModule.State
  readonly university!: University | null;

  get universityLogo() {
    return this.university?.image;
  }
}
