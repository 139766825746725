//
// This file is generated automatically
// every time the WebService runs in dev mode.
// DO NOT CHANGE IT MANUALLY.
//

export type DiscussionItem = {
  id: number;
  creationDate: string;
  author: string;
  subject: string;
  body: string;
  isLocked: boolean;
  answers: AnswerItem[];
}

export type AnswerItem = {
  id: number;
  author: string;
  body: string;
  creationDate: string;
  parent: number;
}

export type CreateDiscussionParameter = {
  author: string;
  subject: string;
  body: string;
  isLocked: boolean;
}

export type CreateAnswerParameter = {
  authorName: string;
  authorEmail: string;
  content: string;
  postId: number;
}

export type DocumentsItem = {
  name: string;
  documentUrl: string;
  author: string;
  size: number;
  space: string;
}

export type FooterItem = {
  title: string;
  roles: string[];
  link: string;
  postId: string;
  linkType: FooterItemLinkType;
}

export enum FooterItemLinkType {
  INTERNAL = 0,
  EXTERNAL = 1,
}

export type FooterContent = {
  htmlContent: string;
}

export type IntroItem = {
  roles: string;
  content: string;
}

export type PanelLinksItem = {
  name: string;
  type: PanelLinksItemType;
  image: string;
  universityLink: boolean;
  linkUrl: string;
  hoverText: string;
  lockerIcon: boolean;
  spaces: string[];
  displayOnlyToSyfadisUsers: boolean;
}

export enum PanelLinksItemType {
  MAINBUTTON = 0,
  IMAGEBUTTON = 1,
  ICONBUTTON = 2,
}

export type Student = {
  foundInSyfadis: boolean;
  code: number;
  trainingPartner: TrainingPartner;
  degree: Degree;
}

export type TrainingPartner = {
  code: string;
  name: string;
}

export type Degree = {
  code: string;
  name: string;
  url: string;
  blocks: Block[];
}

export type Block = {
  code: string;
  name: string;
  theme: string;
  url: string;
  unitValues: UnitValue[];
}

export type UnitValue = {
  code: string;
  name: string;
  theme: string;
  startDate: string|null;
  endDate: string|null;
  successDate: string|null;
  successState: string;
  url: string;
}

export type University = {
  code: string;
  title: string;
  image: string;
  website: string;
}
