
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { formatDate } from "@/helpers/date/dateFormatter";
import { Module } from "@/store/Module";
import { User } from "@/types/User";
import { AnswerItem } from "@/types/generated/DifcamWebTypes";

import ForumAnswerDeletionDialog from "./ForumAnswerDeletionDialog.vue";

const UserModule = namespace(Module.User);

@Component({
  components: {
    ForumAnswerDeletionDialog,
  },
})
export default class ForumAnswerItem extends Vue {
  isDeletionDialogOpen = false;

  @Prop()
  readonly answer!: AnswerItem;

  @UserModule.State
  readonly user!: User;

  get creationDate() {
    return formatDate(this.answer.creationDate);
  }

  closeDeletionDialog() {
    this.isDeletionDialogOpen = false;
  }
}
