
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { formatDate } from "@/helpers/date/dateFormatter";
import { Module } from "@/store/Module";
import { User } from "@/types/User";
import { DiscussionItem } from "@/types/generated/DifcamWebTypes";

import ForumAnswerSection from "./ForumAnswerSection.vue";
import ForumDiscussionDeletionDialog from "./ForumDiscussionDeletionDialog.vue";
import ForumNewAnswer from "./ForumNewAnswer.vue";

const UserModule = namespace(Module.User);

@Component({
  components: {
    ForumAnswerSection,
    ForumNewAnswer,
    ForumDiscussionDeletionDialog,
  },
})
export default class ForumItem extends Vue {
  answerButtonClicked = false;
  isDeletionDialogOpen = false;
  focusInput = false;

  @Prop()
  readonly discussion!: DiscussionItem;

  @UserModule.State
  readonly user!: User;

  get answersLength() {
    return this.discussion.answers.length;
  }

  get creationDate() {
    return formatDate(this.discussion.creationDate);
  }

  get displayNewAnswer() {
    return this.answersLength > 0 || this.answerButtonClicked;
  }

  closeDeletionDialog() {
    this.isDeletionDialogOpen = false;
  }

  displayAnswerButton() {
    this.answerButtonClicked = true;

    this.$nextTick(() => {
      this.focusInput = true;
    });
  }

  get itemLinkDivId() {
    return `discussion-link-${this.discussion.id}`;
  }

  get itemLinkId() {
    return `item-link-${this.discussion.id}`;
  }

  get itemId() {
    return `item-${this.discussion.id}`;
  }

  get itemLinkUrl() {
    if (window.location.hash) {
      return `${window.location.href.split("#")[0]}#${this.discussion.id}`;
    }

    return `${window.location.href}#${this.discussion.id}`;
  }

  selectItemLink() {
    const documentIdElement = document.getElementById(this.itemLinkDivId);
    if (documentIdElement) {
      // (Jeanne G.) Utiliser directement v-show ne fonctionne pas avec le .select()
      documentIdElement.hidden = !documentIdElement.hidden;
    }
    const itemLinkElement = document.getElementById(
      this.itemLinkId
    ) as HTMLInputElement;
    if (itemLinkElement) {
      itemLinkElement.select();
    }
  }

  mounted() {
    const forumItemElement = document.getElementById(this.itemId);
    const headerElement = document.getElementById("header");
    if (headerElement && forumItemElement) {
      const heightHeader = headerElement.style.height;
      forumItemElement.style.scrollMargin = heightHeader;
    }
    if (
      location.hash &&
      forumItemElement &&
      location.hash.substring(1) == this.discussion.id.toString()
    ) {
      forumItemElement.scrollIntoView();
    }
  }
}
