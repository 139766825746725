import { UnitValue } from "@/types/generated/DifcamWebTypes";

// (Tanguy S) 16/09/2022
// I ❤️ Javascript
const minDate = -8640000000000000;

const formatUnitDateLabel = (unitValue: UnitValue) => {
  const today = new Date();
  const startDate = new Date(unitValue.startDate ?? minDate);
  const endDate = new Date(unitValue.endDate ?? minDate);

  if (unitValue.successState == "Yes") {
    return "Fermé";
  } else if (unitValue.successState == "No") {
    return "Fermé - Echec";
  } else if (today >= startDate && today <= endDate) {
    return `En cours - Termine le ${endDate.toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })}`;
  } else if (startDate > today) {
    return "A venir";
  }
};

export { formatUnitDateLabel };
