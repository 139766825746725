
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { discussionService } from "@/services";
import { Module } from "@/store/Module";
import { DiscussionItem } from "@/types/generated/DifcamWebTypes";

const DiscussionModule = namespace(Module.Discussion);

@Component({})
export default class ForumDiscussionDeletionDialog extends Vue {
  isLoading = false;
  isInError = false;

  @Prop()
  discussion!: DiscussionItem;

  @Prop()
  isDeletionDialogOpen!: boolean;

  @Prop()
  closeDeletionDialog!: () => void;

  @DiscussionModule.Action
  loadDiscussions!: () => void;

  refreshDiscussions() {
    this.loadDiscussions();
  }

  handleDelete() {
    this.isLoading = true;
    discussionService
      .deleteDiscussion(this.discussion.id)
      .then(() => {
        this.refreshDiscussions();
        this.closeDeletionDialog();
      })
      .catch(() => {
        this.isInError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
