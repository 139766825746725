import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/src/locale/fr";

// (Tanguy S) 01/08/2022
// Import icon fonts from the @mdi/font package.
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr",
  },
  themes: {
    light: {
      primary: "#008C96",
      secondary: "#b0bec5",
      accent: "#8c9eff",
      error: "#b71c1c",
      background: "#F0F0F0",
    },
  },
});
