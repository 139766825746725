
import { Component, Vue } from "vue-property-decorator";

import PanelIconsGrid from "./PanelIconsGrid.vue";
import PanelImageButton from "./PanelImageButton.vue";
import PanelMainButtons from "./PanelMainButtons.vue";

@Component({
  components: {
    PanelMainButtons,
    PanelImageButton,
    PanelIconsGrid,
  },
})
export default class Panel extends Vue {}
