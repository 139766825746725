
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Module } from "@/store/Module";
import { Role } from "@/types/Role";

import DocumentDisplaySection from "./DocumentDisplaySection.vue";

const UserModule = namespace(Module.User);
const DocumentsModule = namespace(Module.Documents);

@Component({
  components: {
    DocumentDisplaySection,
  },
})
export default class DocumentDisplayer extends Vue {
  @UserModule.State
  readonly currentSpace!: Role | null;

  @DocumentsModule.Action
  loadDocuments!: () => void;

  mounted() {
    this.loadDocuments();
  }

  get spaces() {
    if (this.currentSpace !== Role.Administrator) {
      return [this.currentSpace];
    }

    return [
      Role.Administrator,
      Role.Student,
      Role.Trainer,
      Role.TrainingPartner,
      Role.StudiesDirector,
    ];
  }
}
