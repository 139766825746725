import Vue from "vue";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { studentService, universityService } from "@/services";
import { RemoteData } from "@/types/RemoteData";
import { Role } from "@/types/Role";
import { User } from "@/types/User";
import { Student, University } from "@/types/generated/DifcamWebTypes";

@Module({ namespaced: true })
export class UserModule extends VuexModule {
  user: User | null = null;
  roles: Role[] = [];
  currentSpace: Role | null = null;
  studentInfo: RemoteData<Student> | null = null;
  university: University | null = null;
  foundInSyfadis = false;

  @Mutation
  setUser(user: User) {
    this.user = user;
  }

  @Mutation
  setRoles(roles: Role[]) {
    this.roles = roles;
  }

  @Mutation
  setCurrentSpace(currentSpace: Role) {
    this.currentSpace = currentSpace;
  }

  @Mutation
  setStudentInfo(studentInfo: RemoteData<Student>) {
    this.studentInfo = studentInfo;
  }

  @Mutation
  setUniversity(university: University) {
    this.university = university;
  }

  @Mutation
  setFoundInSyfadis(foundInSyfadis: boolean) {
    this.foundInSyfadis = foundInSyfadis;
  }

  @Action({ rawError: true })
  async loadUserFromCookies() {
    const userData = Vue.$cookies.get("difcamweb_user_client_data");

    if (userData) {
      // Convert from base64 and parse json.
      const decodedUserData = decodeURIComponent(
        atob(userData)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const user = JSON.parse(decodedUserData) as User;

      this.context.commit("setUser", user);

      this.context.commit(
        "setRoles",
        user.roles.filter(
          (role) =>
            role === Role.Administrator ||
            role === Role.Student ||
            role === Role.Trainer ||
            role === Role.TrainingPartner ||
            role === Role.StudiesDirector ||
            role === Role.PortalUser
        )
      );

      // Determine main role
      let mainRole: Role | null = null;
      if (user.roles.includes(Role.Student)) {
        mainRole = Role.Student;
      } else if (user.roles.includes(Role.Trainer)) {
        mainRole = Role.Trainer;
      } else if (user.roles.includes(Role.TrainingPartner)) {
        mainRole = Role.TrainingPartner;
      } else if (user.roles.includes(Role.StudiesDirector)) {
        mainRole = Role.StudiesDirector;
      } else if (user.roles.includes(Role.Administrator)) {
        mainRole = Role.Administrator;
      }

      this.context.commit("setCurrentSpace", mainRole);

      if (
        user.roles.includes(Role.Student) ||
        user.roles.includes(Role.StudiesDirector)
      ) {
        this.context.commit("setStudentInfo", { type: "loading" });

        try {
          const studentInfo = await studentService.getStudent();

          this.context.commit("setStudentInfo", {
            type: "loaded",
            data: studentInfo,
          });
          this.context.commit("setFoundInSyfadis", studentInfo?.foundInSyfadis);

          const universityCode = studentInfo?.trainingPartner?.code;

          if (universityCode) {
            try {
              const university = await universityService.getUniversity(
                universityCode
              );

              if (university) {
                this.context.commit("setUniversity", university);
              }
            } catch {
              // Nothing here.
            }
          }
        } catch {
          this.context.commit("setStudentInfo", { type: "error" });
        }
      }
    }
  }

  @Action({ rawError: true })
  logout() {
    window.location.assign("/api/v0/account/logout");
  }
}
