
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { formatUnitDateLabel } from "@/helpers/degree";
import { Module } from "@/store/Module";
import { RemoteData } from "@/types/RemoteData";
import { Student } from "@/types/generated/DifcamWebTypes";

import CourseErrorHeader from "./CourseErrorHeader.vue";
import CourseHeader from "./CourseHeader.vue";

const UserModule = namespace(Module.User);

@Component({
  components: {
    CourseHeader,
    CourseErrorHeader,
  },
})
export default class Course extends Vue {
  @Prop()
  readonly displayBlocks!: boolean;

  @Prop()
  readonly title!: string;

  @Prop()
  readonly imgSrc!: string;

  @Prop()
  readonly alt!: string;

  @UserModule.State
  readonly studentInfo!: RemoteData<Student> | null;

  @UserModule.State
  readonly foundInSyfadis!: boolean;

  get degree() {
    if (this.studentInfo?.type === "loaded" && this.foundInSyfadis) {
      if (
        Object.values(this.studentInfo.data.degree).every(
          (x) => x === null || x === ""
        )
      ) {
        return null;
      }

      return this.studentInfo.data.degree;
    }
    return null;
  }

  get isInError() {
    return this.studentInfo?.type === "error";
  }

  formatUnitDateLabel = formatUnitDateLabel;
}
