import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { discussionService } from "@/services";
import { DiscussionItem } from "@/types/generated/DifcamWebTypes";

@Module({ namespaced: true })
export class DiscussionsModule extends VuexModule {
  discussions: DiscussionItem[] = [];

  isCreationDialogOpen = false;
  isLoading = false;

  @Mutation
  setIsCreationDialogOpen(isCreationDialogOpen: boolean) {
    this.isCreationDialogOpen = isCreationDialogOpen;
  }

  @Action({ rawError: true })
  openCreationDialog() {
    this.context.commit("setIsCreationDialogOpen", true);
  }

  @Action({ rawError: true })
  closeCreationDialog() {
    this.context.commit("setIsCreationDialogOpen", false);
  }

  @Mutation
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setDiscussions(discussions: DiscussionItem[]) {
    this.discussions = discussions;
  }

  @Action
  loadDiscussions() {
    this.context.commit("setIsLoading", true);
    discussionService
      .getDiscussionItems()
      .then((discussionItems) => {
        this.context.commit("setDiscussions", discussionItems);
      })
      .catch(() => {
        // Nothing here.
      })
      .finally(() => {
        this.context.commit("setIsLoading", false);
      });
  }
}
