
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { getRoleLabel } from "@/helpers/roles";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";

const UserModule = namespace(Module.User);

@Component({})
export default class SpaceSelector extends Vue {
  @UserModule.State
  readonly currentSpace!: Role | null;

  @UserModule.State
  readonly roles!: Role[];

  @UserModule.Mutation
  setCurrentSpace!: (currentSpace: Role) => void;

  displaySpaceRoleLabel(role: Role) {
    if (role !== null) {
      return `Espace ${getRoleLabel(role)}`;
    } else {
      return "";
    }
  }

  updateCurrentSpace(role: Role) {
    this.setCurrentSpace(role);
  }

  get selectableRoles() {
    return this.roles.filter((role) => role !== Role.PortalUser);
  }
}
