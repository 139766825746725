
import { Component, Prop, Vue } from "vue-property-decorator";

import { DocumentsItem } from "@/types/generated/DifcamWebTypes";

@Component({})
export default class DocumentDisplayItem extends Vue {
  @Prop()
  readonly document!: DocumentsItem;

  getDocumentRightFormat = (size: number) => {
    if (size < 10 ** 3) {
      return `${size} o`;
    } else if (size < 10 ** 6) {
      return `${size / 10 ** 3} Ko`;
    } else {
      return `${size / 10 ** 6} Mo`;
    }
  };
}
