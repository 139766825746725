
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import PageContainer from "@/components/Common/PageContainer/PageContainer.vue";
import RoleRibbon from "@/components/Common/RoleRibbon/RoleRibbon.vue";
import { getRoleLabel } from "@/helpers/roles";
import { NamedRoutes } from "@/router/NamedRoutes";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";
import { User } from "@/types/User";

import SpaceSelector from "./SpaceSelector.vue";

const UserModule = namespace(Module.User);

@Component({
  components: {
    PageContainer,
    RoleRibbon,
    SpaceSelector,
  },
})
export default class AppBar extends Vue {
  @UserModule.State
  user!: User;

  @UserModule.State
  readonly currentSpace!: Role | null;

  @UserModule.Action
  logout!: () => void;

  get homeRoute() {
    return { name: NamedRoutes.Home };
  }

  get roleLabel() {
    if (this.currentSpace !== null) {
      return getRoleLabel(this.currentSpace);
    } else {
      return "";
    }
  }

  get displaySettingsTab() {
    return this.currentSpace === Role.Administrator;
  }
}
