import {
  AnswerItem,
  CreateAnswerParameter,
  CreateDiscussionParameter,
  DiscussionItem,
} from "@/types/generated/DifcamWebTypes";

import { BaseService } from "./BaseService";

export class DiscussionService extends BaseService {
  getDiscussionItems(): Promise<DiscussionItem[]> {
    return this.get<DiscussionItem[]>(`/discussion`);
  }

  createDiscussion(
    createDiscussionParameter: CreateDiscussionParameter
  ): Promise<DiscussionItem> {
    return this.post<DiscussionItem>("/discussion", createDiscussionParameter);
  }

  createAnswer(
    createAnswerParameter: CreateAnswerParameter
  ): Promise<AnswerItem> {
    return this.post<AnswerItem>("/discussion/answer", createAnswerParameter);
  }

  deleteDiscussion(id: number) {
    return this.delete<void>(`/discussion/${id}`);
  }

  deleteAnswer(id: number) {
    return this.delete<void>(`/discussion/answer/${id}`);
  }
}
