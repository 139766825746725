
import { Component, Vue } from "vue-property-decorator";

import DocumentDisplayer from "@/components/Common/DocumentsDisplayer/DocumentDisplayer.vue";
import ForumPanel from "@/components/Trainer/Forum/ForumPanel.vue";

@Component({
  components: {
    DocumentDisplayer,
    ForumPanel,
  },
})
export default class AdministratorSpace extends Vue {}
