
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import SectionTitle from "@/components/Common/SectionTitle/SectionTitle.vue";
import { getRoleLabel } from "@/helpers/roles";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";
import { DocumentsItem } from "@/types/generated/DifcamWebTypes";

import DocumentDisplayItem from "./DocumentDisplayItem.vue";

const DocumentsModule = namespace(Module.Documents);

@Component({
  components: {
    SectionTitle,
    DocumentDisplayItem,
  },
})
export default class DocumentDisplaySection extends Vue {
  @Prop()
  readonly space!: Role;

  @DocumentsModule.State
  readonly documents!: DocumentsItem[];

  get roleLabel() {
    return getRoleLabel(this.space);
  }

  get documentsToDisplay() {
    return this.documents.filter((doc) => doc.space === this.space?.toString());
  }
}
