import axios, { CancelTokenSource, Method, ResponseType } from "axios";
import { stringify } from "qs";

import { CancelError } from "./CancelError";

let baseURL = "/api/v0";
if (process.env.NODE_ENV !== "production") {
  baseURL = "/api/v0";
}

const instance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
});

//TODO remettre la partie de dialog
export class BaseService {
  private cancelToken?: CancelTokenSource;

  protected async get<T>(
    uri: string,
    params: object | undefined = undefined,
    responseType?: ResponseType
  ): Promise<T> {
    return await this.request<T>("GET", uri, undefined, responseType, params);
  }

  protected async post<T>(
    uri: string,
    content: object,
    responseType?: ResponseType
  ): Promise<T> {
    return await this.request<T>("POST", uri, content, responseType);
  }

  protected async put<T>(uri: string, content: object): Promise<T> {
    return await this.request<T>("PUT", uri, content);
  }

  protected async patch<T>(uri: string, content: object): Promise<T> {
    return await this.request<T>("PATCH", uri, content);
  }

  protected async delete<T>(uri: string, content?: object) {
    await this.request<T>("DELETE", uri, content);
  }

  public cancel() {
    this.cancelToken?.cancel("Cancel: Demandé par l'utilisateur");
  }

  protected async request<T>(
    method: Method,
    uri: string,
    content?: object,
    responseType?: ResponseType,
    params?: object
  ): Promise<T> {
    try {
      this.cancelToken = axios.CancelToken.source();

      const response = await instance.request({
        method: method,
        url: uri,
        data: content,
        responseType: responseType,
        params: params,
        cancelToken: this.cancelToken.token,
        paramsSerializer: (params) => {
          return stringify(params, {
            arrayFormat: "indices",
            allowDots: true,
            encode: false,
            skipNulls: true,
          });
        },
      });
      return response.data;
    } catch (err) {
      if ((err as Error).message.startsWith("Cancel")) {
        throw new CancelError();
      }

      throw err;
    }
  }
}
