import Vue from "vue";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./plugins/vue-cookies";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

store.dispatch("UserModule/loadUserFromCookies");

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
