
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Module } from "@/store/Module";
import { PanelLinksItem } from "@/types/generated/DifcamWebTypes";

const PanelModule = namespace(Module.Panel);
const UserModule = namespace(Module.User);

@Component({})
export default class PanelMainButtons extends Vue {
  @PanelModule.State
  readonly iconButtonsLink!: PanelLinksItem[];

  @PanelModule.Action
  loadPanelItems!: () => void;

  @UserModule.State
  readonly foundInSyfadis!: boolean;

  mounted() {
    this.loadPanelItems();
  }

  get linksToDisplay() {
    return this.iconButtonsLink.filter(
      (link) => !link.displayOnlyToSyfadisUsers || this.foundInSyfadis
    );
  }
}
