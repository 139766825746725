
import { Component, Vue } from "vue-property-decorator";

import CourseHeader from "@/components/Common/Course/CourseHeader.vue";
import DocumentDisplayer from "@/components/Common/DocumentsDisplayer/DocumentDisplayer.vue";
import ForumPanel from "@/components/Trainer/Forum/ForumPanel.vue";

@Component({
  components: {
    CourseHeader,
    DocumentDisplayer,
    ForumPanel,
  },
})
export default class TrainerSpace extends Vue {}
