
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import AdministratorSpace from "@/components/Administrator/AdministratorSpace.vue";
import AppFooter from "@/components/Common/AppFooter/AppFooter.vue";
import AppIntro from "@/components/Common/AppIntro/AppIntro.vue";
import PageContainer from "@/components/Common/PageContainer/PageContainer.vue";
import RoleRibbon from "@/components/Common/RoleRibbon/RoleRibbon.vue";
import Panel from "@/components/Panel/Panel.vue";
import StudentSpace from "@/components/Student/StudentSpace.vue";
import StudiesDirectorSpace from "@/components/StudiesDirector/StudiesDirectorSpace.vue";
import TrainerSpace from "@/components/Trainer/TrainerSpace.vue";
import TrainingPartnerSpace from "@/components/TrainingPartner/TrainingPartnerSpace.vue";
import { Module } from "@/store/Module";
import { Role } from "@/types/Role";

const UserModule = namespace(Module.User);

@Component({
  components: {
    AppFooter,
    PageContainer,
    RoleRibbon,
    AppIntro,
    AdministratorSpace,
    StudentSpace,
    StudiesDirectorSpace,
    TrainerSpace,
    TrainingPartnerSpace,
    Panel,
  },
})
export default class Home extends Vue {
  @UserModule.State
  readonly currentSpace!: Role | null;

  // Make the Role enum accessible in template;
  Role = Role;
}
