import { FooterContent, FooterItem } from "@/types/generated/DifcamWebTypes";

import { BaseService } from "./BaseService";

export class FooterService extends BaseService {
  loadFooterItems(): Promise<FooterItem[]> {
    return this.get<FooterItem[]>("/footer");
  }

  loadFooterContent(contentId: string): Promise<FooterContent> {
    return this.get<FooterContent>(`/footer/content/${contentId}`);
  }
}
